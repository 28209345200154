/* eslint-disable import/extensions */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, {
 lazy, Suspense, useMemo, useEffect,
} from 'react';
import { BrowserRouter as Router, Route, Navigate } from 'react-router-dom';
import OneSignal from 'react-onesignal';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'
import Context from './context/context';
import ScrollToTop from './components/ScrollToTop';
import CustomSwitch from './components/CustomSwitch';
import Navigation from './components/Navigation';
import FooterNavigation from './components/FooterNavigation';
import Footer from './components/Footer';
import PageNotFound from './views/pages/PageNotFound';
import Interests from './components/Interests';

// Admin
import useGlobalState from './store/useGlobalState';
import translationState from './store/translationState';
import { ThemeProvider } from './context/ThemeContext';
import useToken from './useToken';

// Styles
import './App.css';

// Pages
const Home = lazy(() => import('./views/pages/Home'));
const StripeSingle = lazy(() => import('./components/Payment/StripeSingle'));

const History = lazy(() => import('./views/pages/History'));
const SignIn = lazy(() => import('./views/pages/SignIn'));
const SignUp = lazy(() => import('./views/pages/SignUp'));
const AffiliateLanding = lazy(() => import('./views/pages/AfilliateLanding'));
const AffiliateSignUp = lazy(() => import('./views/pages/AffiliateSignUp'));
const Register = lazy(() => import('./views/pages/Registration'));
const Subscription = lazy(() => import('./views/pages/Subscription'));
const Espees = lazy(() => import('./views/pages/Espees'));
const Results = lazy(() => import('./views/pages/Results'));
const PCDL_1_BILLION_SOLUTIONS = lazy(() => import('./views/pages/1BillionSolutions/home'));
const PCDL_SOLUTIONS = lazy(() => import('./views/pages/Solutions/Home'));
const PCDL_SOLUTIONS_SEARCH = lazy(() => import('./views/pages/Solutions/Search'));
const PCDL_SOLUTIONS_SEARCH_HS = lazy(() => import('./views/pages/Solutions/Search_HS'));
const PCDL_SOLUTIONS_PLAYER = lazy(() => import('./views/pages/Solutions/Player'));
const PCDL_SEARCH = lazy(() => import('./views/pages/Search/Home'));
const AFFILIATE = lazy(() => import('./views/pages/Affiliate/Home'));
const CertificateGenerator = lazy(() => import('./views/pages/CertificateGen'));
const AFFILIATE_NEW = lazy(() => import('./views/pages/Affiliate/selectgrouplink'));
const AFFILIATE_WORDFEST = lazy(() => import('./views/pages/Affiliate/Wordfest'));
const WordfestAdmin = lazy(() => import('./views/pages/Wordfest/Admin'));
const IPPCAdmin = lazy(() => import('./views/pages/IPPC/Admin'));
const WordAtWorkAdmin = lazy(() => import('./views/pages/IPPC/Admin-WordAtWork'));
const ResetPassword = lazy(() => import('./views/pages/ResetPassword'));
const Terms = lazy(() => import('./views/pages/Terms'));
const SWCTerms = lazy(() => import('./views/pages/SWC_Terms'));
const DeleteAccount = lazy(() => import('./views/pages/DeleteAccount'));
const MTCTerms = lazy(() => import('./views/pages/3MTC_Terms'));
const ICLCTerms = lazy(() => import('./views/pages/ICLC_Terms'));
const PROMO_ISM = lazy(() => import('./views/pages/Payment/Promo/ISM'));
const PROMO_LAGOSZ6 = lazy(() => import('./views/pages/Payment/Promo/LagosZ6'));
const Wordfest_Analytics = lazy(() => import('./views/pages/Wordfest/Analytics'));
const Wordfest_Analytics_group = lazy(() => import('./views/pages/Wordfest/Analytics_group'));

const PROMO_SWC = lazy(() => import('./views/pages/Payment/Promo/SWC'));
const PROMO_CEMESEASIA = lazy(() => import('./views/pages/Payment/Promo/PROMO_CEMESEASIA'));
const Privacy = lazy(() => import('./views/pages/Privacy'));
const Testimony = lazy(() => import('./views/templates/Testimony'));
const Documentary = lazy(() => import('./views/templates/Documentary'));

const NewPassword = lazy(() => import('./views/pages/NewPassword'));

const PCDL4KIDS_HOME = lazy(() => import('./views/pages/PCDL4Kids/Home'));
const Quiz = lazy(() => import('./views/pages/Quiz'));
const QuizTemplate = lazy(() => import('./views/templates/Quiz'));
const Dictionary = lazy(() => import('./views/pages/Dictionary/Dictionary'));
const DictionaryTerm = lazy(() => import('./views/pages/Dictionary/Video'));

const Complete = lazy(() => import('./views/pages/Complete'));
const Payment = lazy(() => import('./views/pages/Payment/Payment'));
const PaymentV2 = lazy(() => import('./views/pages/PaymentV2/Payment'));
const PaymentV2SA = lazy(() => import('./views/pages/PaymentV2SA/Payment'));
const PaymentOneTime = lazy(() => import('./views/pages/Payment/OneTime'));
const PROMO_WORD_AT_WORK = lazy(() => import('./views/pages/Payment/Promo/word_at_work'));

const YLWS = lazy(() => import('./views/pages/Payment/Promo/YLWS'));

const SeriesYLWS = lazy(() => import('./views/templates/Series-YLWS'));

const Categories = lazy(() => import('./views/pages/Categories'));
const Languages = lazy(() => import('./views/pages/Languages'));
// const SelectGroupLink = lazy(() => import('./views/pages/SelectGroupLink'));
const Language = lazy(() => import('./views/templates/Language'));
const Category = lazy(() => import('./views/templates/Category'));
const Watch = lazy(() => import('./views/templates/Watch'));
const Landing = lazy(() => import('./views/pages/Landing'));
const Listen = lazy(() => import('./views/templates/Listen'));
const Series = lazy(() => import('./views/templates/Series'));
const Settings = lazy(() => import('./views/pages/Settings'));
const Browse = lazy(() => import('./views/pages/Browse'));
const Library = lazy(() => import('./views/pages/Library'));
const Wordfest = lazy(() => import('./views/pages/Wordfest/Home'));
const Wordfest_Special = lazy(() => import('./views/pages/Wordfest/Special'));
const Wordfest_Quiz = lazy(() => import('./views/pages/Wordfest/Quiz'));
const Wordfest_Quiz_Special = lazy(() => import('./views/pages/Wordfest/Quiz_Special'));
const Wordfest_Quiz_App = lazy(() => import('./views/pages/Wordfest/QuizApp'));
const Wordfest_Video = lazy(() => import('./views/pages/Wordfest/Video'));
const Wordfest_Video_Special = lazy(() => import('./views/pages/Wordfest/Video_Special'));

const Wordfest_Teens = lazy(() => import('./views/pages/Wordfest/Teens/Home'));
const Wordfest_Teens_Quiz = lazy(() => import('./views/pages/Wordfest/Teens/Quiz'));
const Wordfest_Teens_Quiz_App = lazy(() => import('./views/pages/Wordfest/Teens/QuizApp'));
const Wordfest_Teens_Video = lazy(() => import('./views/pages/Wordfest/Teens/Video'));

const Wordfest_Kids = lazy(() => import('./views/pages/Wordfest/Kids/Home'));
const Wordfest_Kids_Quiz = lazy(() => import('./views/pages/Wordfest/Kids/Quiz'));
const Wordfest_Kids_Quiz_App = lazy(() => import('./views/pages/Wordfest/Kids/QuizApp'));
const Wordfest_Kids_Video = lazy(() => import('./views/pages/Wordfest/Kids/Video'));

const Watchlist = lazy(() => import('./views/pages/Watchlist'));

const PCDL_SOLUTIONS_SPECIALSTS = lazy(() => import('./views/pages/SolutionsSpecialist.js'));

const Checkout = lazy(() => import('./views/pages/Checkout'));
const Wallet = lazy(() => import('./views/pages/Wallet'));

const Video = lazy(() => import('./views/templates/Video'));
const Audio = lazy(() => import('./views/templates/Audio'));

const MTC = lazy(() => import('./views/pages/3MTC'));

const UPDATEUSERCODE = lazy(() => import('./views/pages/updateUserCode'));

const HTTN = lazy(() => import('./views/pages/Payment/Promo/HTTN'));
const ICLC_MBTC = lazy(() => import('./views/pages/Payment/Promo/ICLC_MBTC'));

const REGISTER_CODE = lazy(() => import('./views/pages/RegisterCode'));
const REGISTER_CODE2 = lazy(() => import('./views/pages/RegisterCode2'));
const REGISTER_CODE_TEENS = lazy(() => import('./views/pages/RegisterCodeTeens'));
const REGISTER_CODE_KIDS = lazy(() => import('./views/pages/RegisterCodeKids'));
const PCDL_OFFER = lazy(() => import('./views/pages/Payment/Promo/PCDL_OFFER'));
const MTC_OFFER = lazy(() => import('./views/pages/Payment/Promo/3MTC'));

const rollbarConfig = {
  accessToken: '9a359f4d79c84cadafa7cb026283522d',
  environment: 'production',
};

async function runOneSignal() {
  await OneSignal.init({
    appId: 'ff911853-3e4a-4a17-b079-cac47e91d1ec',
    allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.showSlidedownPrompt();
}

function App() {
  const { token, setToken } = useToken();
  const store = useGlobalState();
  const translation = translationState();

  useEffect(() => {
    runOneSignal();
  });

  function External() {
    window.location.href = 'https://pcdlsub.loveworldapis.com/gift/claim/wmc';
    return null;
  }

  return (
    <div className="App relative">
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <Context.Provider value={useMemo(() => ({ store, translation }))}>
            <ThemeProvider>
              <Router>
                <ScrollToTop />
                <Navigation />
                <Interests />

                <div className="content w-full">
                  <Suspense fallback={<h1>Loading...</h1>}>
                    <CustomSwitch>
                      <Route
                        path="signin/*"
                        element={
                          token ? (
                            <Navigate to="/" replace />
                          ) : (
                            <SignIn setToken={setToken} />
                          )
                        }
                      />
                      <Route
                        path="/subscriptions/payment/tier_gold/nigerian/onetime"
                        element={
                          token ? (
                            <Navigate
                              to="/subscriptions/payment/tier_gold/nigeria/onetime"
                              replace
                            />
                          ) : (
                            <Register />
                          )
                        }
                      />
                      <Route
                        path="register"
                        element={
                          token ? <SignIn setToken={setToken} /> : <Register />
                        }
                      />
                      <Route
                        path="/library"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Library />
                        }
                      />
                      {/* <Route
                        path="/3mtc"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <MTC_OFFER />
                        }
                      /> */}
                      <Route
                        path="/wordfest"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest />
                        }
                      />
                      <Route
                        path="/wordfest/special"
                        element={<Wordfest_Special />}
                      />
                      <Route
                        path="/deleteaccount"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <DeleteAccount />
                        }
                      />
                      <Route
                        path="/wordfest/day/:id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Video />
                        }
                      />
                      <Route
                        path="/wordfest/special/day/:id"
                        element={<Wordfest_Video_Special />}
                      />
                      <Route
                        path="/wordfest/quiz/:id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Quiz />
                        }
                      />
                      <Route
                        path="/wordfest/special/quiz/:id"
                        element={<Wordfest_Quiz_Special />}
                      />
                      <Route
                        path="/wordfest/quiz/app/:id/:bridgeId"
                        element={<Wordfest_Quiz_App />}
                      />
                      <Route
                        path="/wordfest/teens"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Teens />
                        }
                      />
                      <Route
                        path="/wordfest/teens/day/:id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Teens_Video />
                        }
                      />
                      <Route
                        path="/wordfest/teens/quiz/:id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Teens_Quiz />
                        }
                      />
                      <Route
                        path="/wordfest/teens/quiz/app/:id/:bridgeId"
                        element={<Wordfest_Teens_Quiz_App />}
                      />

                      <Route
                        path="/wordfest/pcdl4kids"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Kids />
                        }
                      />
                      <Route
                        path="/wordfest/pcdl4kids/day/:id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Kids_Video />
                        }
                      />
                      <Route
                        path="/wordfest/pcdl4kids/quiz/:id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Kids_Quiz />
                        }
                      />
                      <Route
                        path="/wordfest/pcdl4kids/quiz/app/:id/:bridgeId"
                        element={<Wordfest_Kids_Quiz_App />}
                      />
                      <Route
                        path="/wordfest/analytics"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Analytics />
                        }
                      />
                      <Route
                        path="/wordfest/analytics/group"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wordfest_Analytics_group />
                        }
                      />
                      <Route
                        exact
                        path="/pneuma/wf-admin"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <WordfestAdmin />
                          )
                        }
                      />
                      <Route
                        exact
                        path="/pneuma/ippc-admin"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <IPPCAdmin />
                          )
                        }
                      />
                      <Route
                        exact
                        path="/pneuma/wordatwork"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <WordAtWorkAdmin />
                          )
                        }
                      />
                      <Route
                        path="/watchlist"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <Watchlist />
                          )
                        }
                      />
                      {/* <Route
                    path="/terms_conditions"
                    element={
                      <Terms />
                    }
                  /> */}
                      <Route path="/privacy_policy" element={<Privacy />} />
                      <Route path="/gift/claim/wmc" element={<External />} />
                      {/* <Route path="/" element={<Home />} /> */}
                      <Route
                        path="/"
                        element={
                          token ? <Home /> : <Navigate to="/welcome" replace />
                        }
                      />
                      <Route
                        exact
                        path="/stripe-single"
                        element={<StripeSingle />}
                      />
                      <Route exact path="/browse" element={<Browse />} />
                      <Route
                        exact
                        path="/subscriptions"
                        element={<Subscription />}
                      />
                      <Route exact path="/welcome" element={<Landing />} />
                      <Route path="/solutions" element={<PCDL_SOLUTIONS />} />
                      <Route path="/solutions-specialists" element={<PCDL_SOLUTIONS_SPECIALSTS />} />
                      <Route path="/solutions/dictionary" element={<Dictionary />} />
                      <Route path="/solutions/dictionary/:id" element={<DictionaryTerm />} />
                      <Route
                        path="/solutions/search"
                        element={<PCDL_SOLUTIONS_SEARCH />}
                      />
                      <Route
                        path="/solutions/:id/:name"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <PCDL_SOLUTIONS_PLAYER />
                          )
                        }
                      />
                      <Route exact path="/results" element={<Results />} />
                      <Route
                        exact
                        path="/search"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <PCDL_SEARCH />
                          )
                        }
                      />
                      <Route
                        exact
                        path="/pcdl4kids"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <PCDL4KIDS_HOME />
                          )
                        }
                      />
                      <Route
                        path="/subscriptions/payment/:type/:option"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Payment />
                        }
                      />
                      <Route
                        path="/special_offer/lwukzonec/*"
                        element={
                      !token ? <SignIn setToken={setToken} /> : <PROMO_SWC />
                    }
                      />
                      <Route
                        path="/special_offer/cemeseasia"
                        element={
                      !token ? <SignIn setToken={setToken} /> : <PROMO_CEMESEASIA />
                    }
                      />
                      {/* <Route
                        path="/HTTNLivespecial"
                        element={
                      !token ? <SignIn setToken={setToken} /> : <HTTN />
                    }
                      /> */}
                      {/* <Route
                        path="/iclc/*"
                        element={
                      !token ? <SignIn setToken={setToken} /> : <ICLC_MBTC />
                    }
                      /> */}
                      <Route
                        path="/pcdl_offer"
                        element={
                      !token ? <SignIn setToken={setToken} /> : <PCDL_OFFER />
                    }
                      />
                      <Route
                        path="/wordfest/certificate"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <CertificateGenerator />
                        }
                      />
                      <Route
                        path="/wordfest/extravaganza"
                        element={
                          !token ? <SignIn setToken={setToken} /> : (
                            <Navigate
                              to="/wordfest/day/28"
                              replace
                            />
)
                        }
                      />
                      <Route
                        path="/swc/terms"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <SWCTerms />
                        }
                      />
                      <Route
                        path="/3mtc/terms"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <MTCTerms />
                        }
                      />
                      <Route
                        path="/iclc/terms"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <ICLCTerms />
                        }
                      />
                      <Route
                        path="/ism/"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <PROMO_ISM />
                        }
                      />
                      <Route
                        path="/special_offer/celcc6grouplz1"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <PROMO_LAGOSZ6 />
                        }
                      />
                      <Route
                        path="/ylws/*"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <YLWS />
                        }
                      />
                      <Route
                        path="/wordatwork/*"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <PROMO_WORD_AT_WORK />
                          )
                        }
                      />
                      <Route
                        path="/selectgrouplink"
                        element={<AFFILIATE_NEW />}
                      />
                      <Route
                        path="/ref/teens/:zone_id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <REGISTER_CODE_TEENS />
                        }
                      />
                      <Route
                        path="/ref/kids/:zone_id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <REGISTER_CODE_KIDS />
                        }
                      />
                      <Route
                        path="/ref/special/:zone_id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <REGISTER_CODE_KIDS />
                        }
                      />
                      <Route
                        path="/ref/:zone_id/:group_id"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <REGISTER_CODE />
                        }
                      />
                      <Route
                        path="/subscribe/pcdlglobal"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <REGISTER_CODE2 />
                        }
                      />
                      {/* <Route
                    path="/subscriptions/payment/:type/international/onetime"
                    element={
                      !token ? (
                        <SignIn setToken={setToken} />
                      ) : (
                        <PaymentInternationalOneTime />
                      )
                    }
                  /> */}
                      {/* <Route
                    path="/subscriptions/payment/:type/nigeria/onetime"
                    element={
                      !token ? (
                        <SignIn setToken={setToken} />
                      ) : (
                        <PaymentNigeriaOneTime />
                      )
                    }
                  /> */}
                      <Route
                        path="/espees"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Espees />
                        }
                      />
                      {/* <Route
                        path="/ippc/:code"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <IPPC_TEAM />
                        }
                      /> */}
                      <Route
                        path="/1bs"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <PaymentV2 />
                          )
                        }
                      />
                      <Route
                        path="/SA-gift"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <PaymentV2SA />
                          )
                        }
                      />
                      <Route
                        path="/subscriptions/payment/:type/"
                        element={
                          !token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <PaymentOneTime />
                          )
                        }
                      />
                      <Route
                        exact
                        path="/subscriptions/payment/complete/:transactionId"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Complete />
                        }
                      />
                      <Route
                        exact
                        path="/history"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <History />
                        }
                      />
                      <Route exact path="/interests/" element={<Interests />} />
                      <Route exact path="/signin/*" element={<SignIn />} />
                      <Route exact path="/checkout" element={<Checkout />} />
                      <Route
                        exact
                        path="/resetpassword"
                        element={<ResetPassword />}
                      />
                      <Route
                        exact
                        path="/forgotpassword/reset/:code"
                        element={<NewPassword />}
                      />
                      <Route
                        path="signup/*"
                        element={
                          token ? <SignIn setToken={setToken} /> : <SignUp />
                        }
                      />
                      <Route
                        exact
                        path="/join/:affiliate"
                        element={
                          token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <AffiliateLanding />
                          )
                        }
                      />
                      <Route
                        exact
                        path="/join/:affiliate/signup"
                        element={
                          token ? (
                            <SignIn setToken={setToken} />
                          ) : (
                            <AffiliateSignUp />
                          )
                        }
                      />
                      <Route
                        exact
                        path="/tools/affiliate"
                        element={<AFFILIATE />}
                      />
                      <Route
                        exact
                        path="/tools/affiliate/wordfest"
                        element={<AFFILIATE_WORDFEST />}
                      />
                      <Route exact path="/quiz" element={<Quiz />} />
                      <Route
                        exact
                        path="/quiz/:id/"
                        element={<QuizTemplate />}
                      />
                      <Route
                        exact
                        path="/categories"
                        element={<Categories />}
                      />
                      <Route
                        path="/listen/:id/*"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Listen />
                        }
                      />
                      <Route
                        path="/series/:id/:title/:lan/:lan_id/*"
                        element={<Series />}
                      />
                      <Route path="/series/:id/*" element={<Series />} />
                      <Route
                        path="/watch/:id/*"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Watch />
                        }
                      />
                      <Route path="testimony/:id/*" element={<Testimony />} />
                      <Route
                        path="documentary/:id/*"
                        element={<Documentary />}
                      />
                      <Route
                        path="/wallet"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Wallet />
                        }
                      />
                      <Route
                        path="/watchlist/video/:id/:name"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Video />
                        }
                      />
                      <Route
                        path="/watchlist/audio/:id/:name"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Audio />
                        }
                      />
                      <Route
                        path="/categories/:id/:name"
                        element={<Category />}
                      />
                      <Route
                        path="/categories/yB4dKm3LNec/your-loveworld-specials"
                        element={(
                          <Navigate
                            to="/series/hGPFmF-VlVI/your-loveworld-specials"
                            replace
                          />
                        )}
                      />
                      <Route
                        path="/series/hGPFmF-VlVI/your-loveworld-specials"
                        element={<SeriesYLWS />}
                      />
                      <Route
                        path="/categories/XR7p8b5icds/languages"
                        element={<Languages />}
                      />
                      <Route
                        path="/categories/languages/:title/:id"
                        element={<Language />}
                      />
                      <Route
                        path="/onebillionsolutions/*"
                        element={<PCDL_1_BILLION_SOLUTIONS />}
                      />
                      <Route
                        path="/settings"
                        element={
                          !token ? <SignIn setToken={setToken} /> : <Settings />
                        }
                      />
                      <Route path="*" element={<PageNotFound />} />
                    </CustomSwitch>
                  </Suspense>
                </div>
                <Footer />
                <FooterNavigation />
              </Router>
            </ThemeProvider>
          </Context.Provider>
        </ErrorBoundary>
      </Provider>
    </div>
  );
}

export default App;
